import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import styles from './styles.module.scss';

export const MainContent = () => {
  const navigate = useNavigate();

  const loadingVariants = {
    hidden: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
      transition: { delay: 6 },
    },
    visible: {
      opacity: 1,
      display: 'unset',
    },
  };

  const handleClick = async () => {
    navigate('/connect');
  };

  return (
    <div className={styles.main_content} onClick={handleClick}>
      <div className={styles.content}>
        <div className={styles.title}>
          For <br />
          what <br />
          it’s <br />
          worth
        </div>
        <span />
        <div className={styles.subtitle}>
          Ryan <br />
          Gander
        </div>
      </div>
      {/* <motion.div
        className={styles.loading}
        initial="visible"
        variants={loadingVariants}
        animate="hidden"
      >
        <Loading />
      </motion.div> */}
    </div>
  );
};
