import { ReactComponent as MenuIconSVG } from '../../assets/images/menu_icon.svg';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import MenuIconMui from '@mui/icons-material/Menu';

interface MenuIconProps {
  isBlack?: boolean;
}

const MenuIcon = ({ isBlack }: MenuIconProps) => {
  const navigate = useNavigate();

  const handleMenuClick = () => {
    navigate('/menu');
  };
  return (
    <MenuIconMui
      fontSize="large"
      className={`${styles.menu_icon} ${isBlack ? styles.black : ''}`}
      onClick={handleMenuClick}
    />
  );
};

export default MenuIcon;
