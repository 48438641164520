import styles from './styles.module.scss';
const PC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>For What it’s Worth</div>
      <div className={styles.author}>Ryan Gander</div>
      <div className={styles.description}>
        An individual NFT taking the form of an individual card from a full set
        of 52 playing cards, plated in 24-karat gold, with a unique illustration
        on one side, relating to different symbols of value.
      </div>
      <div className={styles.mobileOnly}>
        This site is designed for mobile devices only. Please access using a
        mobile device.
      </div>
      <div className={styles.footer}>
        Copyright © 2023 Ryan Gander. All right reserved.
      </div>
    </div>
  );
};

export default PC;
