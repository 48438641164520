import { isFirefox, isMobile } from 'react-device-detect';
import Web3 from 'web3';
import { Web3ContractEncoder } from '../../contract';
import {
  InvalidTransactionReceiptException,
  TransactionException,
  TransactionExceptionType,
} from '../../exception/transaction';
import { EthereumInjectedWindow } from '../../interface/ethereum';
import { MetamaskEthereum } from '../../interface/metamask';
import {
  ITransactionData,
  ITransactionResult,
} from '../../interface/transaction';

const META_INSTALL_LINK_CHROME =
  'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn';
const META_INSTALL_LINK_FIREFOX =
  'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/';

export const MetamaskConnector = (() => {
  let onAddressChangeCallback: any = null;
  const state = { address: '' };
  let web3Inst: Web3;
  const { ethereum } = window as EthereumInjectedWindow;
  const contractEncoder = Web3ContractEncoder;
  contractEncoder.config(ethereum);

  const setAddressChangeCallback = (callback: (address: string) => void) => {
    onAddressChangeCallback = callback;
  };

  const getCurrentAddress = () => state.address;

  const setCurrentAddress = (address: string) => {
    state.address = address;
    if (onAddressChangeCallback) {
      onAddressChangeCallback(address);
    }
  };

  const hasExtension = () => {
    const ethereumInst = ethereum as MetamaskEthereum;
    if (!ethereumInst) {
      return false;
    }

    return ethereumInst.isMetaMask ?? false;
  };

  const activate = async () => {
    const ethereumInst = ethereum as MetamaskEthereum;
    if (!ethereumInst) {
      return false;
    }

    try {
      const accounts = await ethereumInst.request({
        method: 'eth_requestAccounts',
      });

      if (accounts.length === 0) return false;

      const [address] = accounts;
      setCurrentAddress(address);

      web3Inst = new Web3(ethereumInst);
    } catch (e) {
      return false;
    }

    return true;
  };

  const restoreState = (address: string) => {
    const ethereumInst = ethereum as MetamaskEthereum;
    if (!ethereumInst) {
      return false;
    }

    web3Inst = new Web3(ethereumInst);

    setCurrentAddress(address);

    return true;
  };

  const getInstallLink = () => {
    if (isFirefox) return META_INSTALL_LINK_FIREFOX;

    return META_INSTALL_LINK_CHROME;
  };

  const getDappLink = (link: string) =>
    `https://metamask.app.link/dapp/${link}`; // Don't remove the protocol string

  const sign = async (message: string) => {
    const ethereumInst = ethereum as MetamaskEthereum;
    if (!ethereumInst) {
      throw new Error(
        'Failed to sign message: Ethereum provider is not available',
      );
    }

    try {
      const signature = await ethereumInst.request({
        method: 'personal_sign',
        params: [message, state.address],
      });

      return signature;
    } catch (e) {
      return '';
    }
  };

  const sendTransaction = async (tx: ITransactionData) => {
    const { abi, params } = tx;
    const contract = contractEncoder.encodeContract(abi, tx.to);

    try {
      const gas = await contract.methods[tx.functionName](
        ...params,
      ).estimateGas({
        from: state.address,
        value: tx.value,
      });
      const receipt = await web3Inst?.eth.sendTransaction({
        from: state.address,
        to: tx.to,
        value: tx.value,
        gas: tx.gas || gas,
        gasPrice: tx.gasPrice,
        // NOTE: https://stackoverflow.com/questions/68926306/avoid-this-gas-fee-has-been-suggested-by-message-in-metamask-using-web3
        maxFeePerGas: tx.maxFeePerGas,
        maxPriorityFeePerGas: tx.maxPriorityFeePerGas,
        data: contract.methods[tx.functionName](...params).encodeABI(),
        nonce: tx.nonce,
        chainId: tx.chainId,
        hardfork: tx.hardfork,
        common: tx.common,
      });

      if (!receipt) throw new InvalidTransactionReceiptException();

      const result: ITransactionResult = {
        transactionHash: receipt.transactionHash,
        receipt: {
          status: receipt.status ? '1' : '0',
          transactionHash: receipt.transactionHash,
          transactionIndex: receipt.transactionIndex,
          blockHash: receipt.blockHash,
          blockNumber: receipt.blockNumber,
          gasUsed: receipt.gasUsed,
          from: receipt.from,
          to: receipt.to,
        },
        nativeReceipt: receipt,
      };

      return result;
    } catch (e) {
      if (e instanceof InvalidTransactionReceiptException) {
        throw e;
      }

      // TODO: Add additional error handling for different types of wallet errors
      throw new TransactionException(
        TransactionExceptionType.GENERAL,
        e as string,
      );
    }
  };

  const getBalance = async () => {
    if (!web3Inst) return null;

    try {
      const rawBalance = await web3Inst.eth.getBalance(state.address);
      const balance = web3Inst.utils.fromWei(rawBalance, 'ether');

      return balance;
    } catch (e) {
      return null;
    }
  };

  const that = {
    ...state,
    setAddressChangeCallback,
    getCurrentAddress,
    hasExtension,
    activate,
    restoreState,
    getInstallLink,
    getDappLink,
    sign,
    sendTransaction,
    getBalance,
  };

  return that;
})();
