import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DcentLogo } from '../../assets/images/dcent_logo.svg';
import { DcentConnector } from '../../wallet/connector/DcentConnector/DcentConnector';
import WalletManager from '../../wallet/walletManager';
import styles from './styles.module.scss';

export const BeforeConnect = () => {
  const navigate = useNavigate();

  const handleConnect = async (e: Event) => {
    const connector = DcentConnector;

    if (isMobile && !connector.hasExtension()) {
      window.open(connector.getInstallLink());
      console.log('no extension');

      return;
    }

    const res = await connector.activate();
    WalletManager.getInstance().setWalletConnector(connector);
    if (res) {
      console.log('success');
      navigate('/connected');
    } else {
      console.log('fail');
      navigate('/');
    }
  };

  const handleClick = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
    // navigate('/');
  };

  return (
    <div className={styles.wallet_connect} onClick={(e: any) => handleClick(e)}>
      <DcentLogo />
      <div className={styles.text} onClick={(e: any) => handleConnect(e)}>
        Connect wallet
      </div>
    </div>
  );
};
