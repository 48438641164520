import { createContext, useState } from 'react';

interface Metadata {
  animation_url: string;
  sentence: string;
  image: string;
  attributes: {
    trait_type: string;
    value: string;
  }[];
}

const MetadataContext = createContext({
  metadata: {} as Metadata,
  changeMetadata: (metadata: Metadata) => {},
});

interface MetadataProviderProps {
  children: React.ReactNode;
}

const MetadataProvider = ({ children }: MetadataProviderProps) => {
  const [metadata, setMetadata] = useState<Metadata>({} as Metadata);
  const changeMetadata = (metadata: Metadata) => {
    setMetadata(metadata);
  };

  return (
    <MetadataContext.Provider value={{ metadata, changeMetadata }}>
      {children}
    </MetadataContext.Provider>
  );
};

export { MetadataContext, MetadataProvider };
