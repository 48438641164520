import { useLocation } from 'react-router-dom';
import { MainContent } from '../../components/MainContent';
import { Menu } from '../../components/Menu';
import { AfterConnect, BeforeConnect } from '../../components/WalletConnect';
import styles from './styles.module.scss';
import {
  MyNFT,
  ProjectCard,
  ProjectCardFamily,
  ProjectIntro,
} from '../../components/MenuItem';
import WalletManager from '../../wallet/walletManager';
import { useContext, useEffect } from 'react';
import { MetadataContext } from '../../contexts/MetadataContext';
import { useContract } from '../../hooks/useContract';
import { Artist } from '../../components/Artist';
import { Copyright } from '../../components/Copyright';
import { motion, AnimatePresence } from 'framer-motion';

export const Main = () => {
  const address = WalletManager.getInstance().getCurrentAddress();
  const location = useLocation();
  const contract = useContract();
  const { changeMetadata } = useContext(MetadataContext);

  const pageVariants = {
    initial: {
      opacity: 0,
      x: 0,
      overflow: 'hidden',
    },
    in: {
      opacity: 1,
      x: 0,
      overflow: 'hidden',
      transition: {
        ease: 'easeInOut',
        duration: 1,
      },
      transitionEnd: {
        overflow: 'visible',
      },
    },
    out: {
      zIndex: 100,
      opacity: 1,
      x: '-15vw',
      skewY: '-20deg',
      rotateY: '-90deg',
      // rotateZ: '-5deg',
      overflow: 'hidden',
      transition: {
        ease: 'easeInOut',
        duration: 1,
      },
    },
  };

  useEffect(() => {
    if (address) {
      const getTokenId = async () => {
        const tokenId = await contract.getTokenId();

        return tokenId;
      };

      const getTokenMetadata = async () => {
        const tokenId = await getTokenId();
        if (tokenId === null) return;

        const metadata = await contract.getTokenMetadata(tokenId);
        if (metadata === null) return;

        changeMetadata(metadata);
      };

      getTokenMetadata();
    }
  }, [address]);

  return (
    <div className={styles.main_wrapper}>
      <AnimatePresence mode="popLayout">
        <motion.div
          key={location.pathname}
          className={styles.main}
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          {location.pathname === '/' && <MainContent />}
          {location.pathname === '/artist' && <Artist />}
          {location.pathname === '/connect' && <BeforeConnect />}
          {location.pathname === '/connected' && <AfterConnect />}
          {location.pathname === '/menu' && <Menu />}
          {location.pathname === '/project' && <ProjectIntro />}
          {location.pathname === '/project/cards' && <ProjectCard />}
          {location.pathname === '/project/cards/family' && (
            <ProjectCardFamily />
          )}
          {location.pathname === '/mynft' && <MyNFT />}
          {location.pathname === '/copyright' && <Copyright />}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
