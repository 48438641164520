import styles from './styles.module.scss';
import { ReactComponent as CopyrightIcon } from '../../assets/images/copyright_icon.svg';
import TitleHeader from '../TitleChip/TitleHeader';
import logo from '../../assets/images/galleryhyundailogo.png';

export const Copyright = () => {
  return (
    <div className={styles.wrapper}>
      <TitleHeader icon={<CopyrightIcon />} text={'Copyright'} />
      <p>
        <b>Copyright © 2023 Ryan Gander.</b>
      </p>
      <br />
      <p>
        All rights reserved. No part of this artwork may be reproduced,
        distributed, or transmitted in any form or by any means, including
        photocopying, recording, or other electronic or mechanical methods,
        without the prior written permission of the publisher, except in the
        case of brief quotations embodied in critical reviews and certain other
        noncommercial uses permitted by copyright law. For permission requests,
        write to the publisher, addressing "Attention: Permissions Coordinator,"
        at the address below:
      </p>
      <br />
      <p>
        <b>Designed by</b> Hyer.
        <br />
        <b>Edited by</b> etnah company.
        <br />
        <b>Published by</b> Gallery Hyundai
        <br />
        <b>Distributed by</b> Gallery Hyundai
      </p>
      <br />
      <p>
        <img src={logo} width="80%" alt="" />
      </p>
      <p>
        14 Samcheong-ro, Jongno-gu, Seoul 03062
        <br />
        Korea
      </p>
      <br />
    </div>
  );
};
