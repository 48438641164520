import style from './style.module.scss';

interface LanguageToggleProps {
  isKorean?: boolean;
  onClick?: () => void;
}

const LanguageToggle = ({
  isKorean = false,
  onClick = () => {},
}: LanguageToggleProps) => {
  return (
    <div className={style.languageToggleWrapper} onClick={onClick}>
      {isKorean && (
        <>
          <div className={style.koreanLeft}>한</div>
          <div className={style.koreanRight}>A</div>
        </>
      )}
      {!isKorean && (
        <>
          <div className={style.englishLeft}>한</div>
          <div className={style.englishRight}>A</div>
        </>
      )}
    </div>
  );
  if (isKorean)
    return (
      <div className={style.languageToggleWrapper}>
        <div className={style.koreanLeft}>한</div>
        <div className={style.koreanRight}>A</div>
      </div>
    );
  else
    return (
      <div className={style.languageToggleWrapper}>
        <div className={style.englishLeft}>한</div>
        <div className={style.englishRight}>A</div>
      </div>
    );
};

export default LanguageToggle;
