import { getContractInstance } from '../contract';
import WalletManager from '../wallet/walletManager';

export const useContract = () => {
  const address = WalletManager.getInstance().getCurrentAddress();
  const contractInstance = getContractInstance();

  const getTokenId = async () => {
    if (!address) return null;

    const tokenId = await contractInstance.methods
      .tokenOfOwnerByIndex(address, 0)
      .call();

    return tokenId;
  };

  const getTokenMetadata = async (tokenId: string) => {
    if (!tokenId) return null;

    const metadataUrl = await contractInstance.methods.tokenURI(tokenId).call();

    try {
      const metadata = await fetch(metadataUrl);
      return metadata.json();
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return {
    getTokenId,
    getTokenMetadata,
  };
};
