import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckIcon } from '../../assets/images/check_icon.svg';
import styles from './styles.module.scss';
import { useEffect } from 'react';

export const AfterConnect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/menu');
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.wallet_connect}>
      <CheckIcon />
      <div className={styles.text}>Connect wallet</div>
    </div>
  );
};
