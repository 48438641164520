import { ReactComponent as ArtistIcon } from '../../assets/images/artist_icon.svg';
import { ReactComponent as ProjectIcon } from '../../assets/images/project_icon.svg';
import { ReactComponent as MynftIcon } from '../../assets/images/mynft_icon.svg';
import { ReactComponent as CopyrightIcon } from '../../assets/images/copyright_icon.svg';
import { ReactComponent as MenuHighlight } from '../../assets/images/menu_highlight.svg';
import { motion } from 'framer-motion';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export const Menu = () => {
  const navigate = useNavigate();
  const [menuItem, setMenuItem] = useState<string>('');

  const container = {
    hidden: { opacity: 1 },
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { x: -200, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const handleMenuClick = (route: string) => {
    setMenuItem(route);
    setTimeout(() => {
      navigate(route);
    }, 500);
  };

  return (
    <div className={styles.menu}>
      <motion.div
        className={styles.menu_container}
        initial="hidden"
        animate="visible"
        variants={container}
      >
        <motion.div
          className={`${styles.menu_item} ${
            menuItem === 'artist' && styles.active
          }`}
          variants={item}
          onClick={() => handleMenuClick('artist')}
        >
          <div className={styles.icon_container}>
            <ArtistIcon />
          </div>
          <div className={styles.menu_name}>Ryan Gander</div>
          <div className={styles.menu_highlight}>
            <MenuHighlight />
          </div>
        </motion.div>
        <motion.div
          className={`${styles.menu_item} ${
            menuItem === 'project' && styles.active
          }`}
          variants={item}
          onClick={() => handleMenuClick('project')}
        >
          <div className={styles.icon_container}>
            <ProjectIcon />
          </div>
          <div className={styles.menu_name}>The Project</div>
          <div className={styles.menu_highlight}>
            <MenuHighlight />
          </div>
        </motion.div>
        <motion.div
          className={`${styles.menu_item} ${
            menuItem === 'mynft' && styles.active
          }`}
          variants={item}
          onClick={() => handleMenuClick('mynft')}
        >
          <div className={styles.icon_container}>
            <MynftIcon />
          </div>
          <div className={styles.menu_name}>My NFT</div>
          <div className={styles.menu_highlight}>
            <MenuHighlight />
          </div>
        </motion.div>
        <motion.div
          className={`${styles.menu_item} ${
            menuItem === 'copyright' && styles.active
          }`}
          variants={item}
          onClick={() => handleMenuClick('copyright')}
        >
          <div className={styles.icon_container}>
            <CopyrightIcon />
          </div>
          <div className={styles.menu_name}>Copyright</div>
          <div className={styles.menu_highlight}>
            <MenuHighlight />
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};
