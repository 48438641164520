import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Main } from './pages/Main';
import WalletManager from './wallet/walletManager';
import { MetadataProvider } from './contexts/MetadataContext';
import useMobile from './hooks/useMobile';
import PC from './components/PC/PC';
import './i18n';

WalletManager.config({
  appName: "For What it's Worth",
  walletAutoConnect: true,
});

function App() {
  const { isMobile } = useMobile();
  return (
    <MetadataProvider>
      <div className="App">
        {isMobile && (
          <Router>
            <Routes>
              <Route path="/*" element={<Main />} />
            </Routes>
          </Router>
        )}
        {!isMobile && <PC />}
      </div>
    </MetadataProvider>
  );
}

export default App;
