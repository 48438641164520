import style from './Artist.module.scss';
import Gander from '../../assets/images/gander.jpg';
// import { ReactComponent as NextIcon } from '../../assets/images/next_icon.svg';
import { ReactComponent as ArtistIcon } from '../../assets/images/artist_icon.svg';
import LanguageToggle from '../LanguageToggle/LanguageToggle';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NextIcon from '@mui/icons-material/ArrowForward';
import TitleHeader from '../TitleChip/TitleHeader';

export const Artist = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const changeLanguage = () => {
    if (i18n.language === 'ko') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('ko');
    }
  };

  return (
    <div className={style.pageWrapper}>
      <TitleHeader icon={<ArtistIcon />} text={'Ryan Gander'} />
      {/* <div className={style.fadeoutTop}></div> */}
      {/* <div className={style.fadeoutBottom}></div> */}
      <div className={style.picture}>
        <img src={Gander} alt="" />
      </div>
      <div className={style.titleWrapper}>
        <p className={style.title}>Ryan Gander OBE RA</p>
        <p className={style.subtitle}>1976 CHESTER, UK</p>
      </div>
      <div className={style.caption}>
        Photography by Benjamin McMahon courtesy the Royal Academy of Arts,
        London
      </div>
      {/* <div className={style.description_wrapper}> */}
      <div
        className={
          i18n.language === 'ko' ? style.descriptionKr : style.description
        }
      >
        <p>{t('ARTIST_DESCRIPTION_1')}</p>
        <p>{t('ARTIST_DESCRIPTION_2')}</p>
        <p>{t('ARTIST_DESCRIPTION_3')}</p>
        <p>{t('ARTIST_DESCRIPTION_4')}</p>
        <p>{t('ARTIST_DESCRIPTION_5')}</p>
        <p>{t('ARTIST_DESCRIPTION_6')}</p>
        <p>{t('ARTIST_DESCRIPTION_7')}</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      {/* </div> */}
      <LanguageToggle
        isKorean={i18n.language === 'ko'}
        onClick={() => {
          changeLanguage();
        }}
      />
      <div
        className={style.nextIconWrapper}
        onClick={() => {
          navigate('/project');
        }}
      >
        <NextIcon fontSize="large" />
      </div>
    </div>
  );
};
