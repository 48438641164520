import style from './styles.module.scss';
import MenuIcon from '../MenuItem/MenuIcon';

interface TitleHeaderProps {
  icon: React.ReactNode;
  text: string;
  isBlack?: boolean;
}

const TitleHeader = ({ icon, text, isBlack = false }: TitleHeaderProps) => {
  return (
    <div className={style.titleHeader}>
      <div className={style.menuIcon}>
        <MenuIcon isBlack={isBlack} />
      </div>
      <div className={style.titleWrapper}>
        {icon}
        {text}
      </div>
    </div>
  );
};

export default TitleHeader;
