import { useNavigate } from 'react-router-dom';
import { ReactComponent as AttentionIcon } from '../../assets/images/attention_icon.svg';
import { ReactComponent as CurrencyIcon } from '../../assets/images/currency_icon.svg';
import { ReactComponent as OpportunityIcon } from '../../assets/images/opportunity_icon.svg';
// import { ReactComponent as PrevIcon } from '../../assets/images/prev_icon.svg';
import PrevIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as ProjectIcon } from '../../assets/images/project_icon.svg';
import { ReactComponent as TimeIcon } from '../../assets/images/time_icon.svg';
import { ReactComponent as TimeCard } from '../../assets/images/time_card.svg';
import { ReactComponent as AttentionCard } from '../../assets/images/attention_card.svg';
import { ReactComponent as CurrencyCard } from '../../assets/images/currency_card.svg';
import { ReactComponent as OpportunityCard } from '../../assets/images/opportunity_card.svg';
import { MenuItemLayout } from './MenuItemLayout';
import styles from './styles.module.scss';
import TitleChip from '../TitleChip/TitleChip';
import { useState } from 'react';
import TitleHeader from '../TitleChip/TitleHeader';

export const ProjectCard = () => {
  const width = window.innerWidth;
  const cardWidth = width / 2 - 22;
  const navigate = useNavigate();
  const [clickedSuit, setClickedSuit] = useState<string>('');

  const handlePrevClick = () => {
    navigate('/project');
  };

  const handleClick = (card: string) => {
    setClickedSuit(card);
    setTimeout(() => {
      navigate(`/project/cards/family?suit=${card}`);
    }, 500);
  };

  const getCardComponent = (card: string) => {
    let Icon;
    let iconSize;
    let iconBigSize;

    switch (card) {
      case 'Time':
        Icon = TimeIcon;
        iconSize = (14 / 195) * cardWidth;
        iconBigSize = (84 / 195) * cardWidth;
        break;
      case 'Attention':
        Icon = AttentionIcon;
        iconSize = (20 / 195) * cardWidth;
        iconBigSize = (112 / 195) * cardWidth;
        break;
      case 'Currency':
        Icon = CurrencyIcon;
        iconSize = (20 / 195) * cardWidth;
        iconBigSize = (104 / 195) * cardWidth;
        break;
      case 'Opportunity':
        Icon = OpportunityIcon;
        iconSize = (30 / 195) * cardWidth;
        iconBigSize = (131 / 195) * cardWidth;
        break;
      default:
        Icon = TimeIcon;
        iconSize = (14 / 195) * cardWidth;
        iconBigSize = (84 / 195) * cardWidth;
        break;
    }

    return (
      <div
        className={styles.card}
        style={{ width: cardWidth, height: (cardWidth * 284) / 195 }}
        onClick={() => handleClick(card)}
      >
        <div className={styles.top_bar}>
          <Icon style={{ width: iconSize }} />
          {card}
        </div>
        <div className={styles.center}>
          <Icon style={{ width: iconBigSize }} />
          {card}
        </div>
        <div className={styles.bottom_bar}>
          {card}
          <Icon style={{ width: iconSize }} />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.project} style={{ background: '#fff' }}>
      <TitleHeader icon={<ProjectIcon />} text={'The Project'} isBlack />
      <div className={styles.card_content}>
        {/* {getCardComponent('Time')}
        {getCardComponent('Attention')}
        {getCardComponent('Currency')}
        {getCardComponent('Opportunity')} */}
        <TimeCard
          className={clickedSuit === 'Time' ? styles.active : ''}
          onClick={() => handleClick('Time')}
        />
        <AttentionCard
          className={clickedSuit === 'Attention' ? styles.active : ''}
          onClick={() => handleClick('Attention')}
        />
        <CurrencyCard
          className={clickedSuit === 'Currency' ? styles.active : ''}
          onClick={() => handleClick('Currency')}
        />
        <OpportunityCard
          className={clickedSuit === 'Opportunity' ? styles.active : ''}
          onClick={() => handleClick('Opportunity')}
        />
      </div>
      <div className={`${styles.footer} ${styles.prev_icon}`}>
        <div className={`${styles.icon_container}`}>
          <PrevIcon
            fontSize="large"
            style={{ color: '#000' }}
            onClick={handlePrevClick}
          />
        </div>
      </div>
    </div>
  );
};
