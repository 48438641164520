import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import contractJson from '../abi/ForWhatItsWorth.json';

export const ETH_NODE_URL =
  process.env.REACT_APP_ENV === 'prod'
    ? 'https://rpc.ankr.com/eth'
    : 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161	';

export const contractAddress =
  process.env.REACT_APP_ENV === 'prod'
    ? '0x9db4d2103f050ab9c5962ce719798188f93e3434'
    : '0x0f56fad08467e15E67Aa0e0EA2dF0bCAC21769e0';

export function getWeb3Instance() {
  return new Web3(new Web3.providers.HttpProvider(ETH_NODE_URL));
}

export function getContractInstance() {
  const web3 = getWeb3Instance();
  return new web3.eth.Contract(contractJson.abi as AbiItem[], contractAddress);
}
