import { MetamaskConnector } from './connector/MetamaskConnector/MetamaskConnector';
import { sessionStorageHelper } from './util/session';

interface IWalletManagerOptions {
  baseUrl?: string;
  appName?: string;
  appLogoUrl?: string;
  jsonRpcUrl?: string;
  chainId?: number;
  targetChainId?: number;
  walletAutoConnect?: boolean;
  useAsMobxStore?: boolean;
  autoDisconnectOnChainMismatch?: boolean;
}

const WALLET_STATE_KEY = 'walletState';
const MAX_WALLET_CONNECTION_TIME = {
  hour: 6,
};

const WalletManagerInst = (() => {
  let walletOptions: IWalletManagerOptions;
  let address: string = '';
  let connector: any;
  const observers: Function[] = [];

  // private methods
  const setCurrentAddress_ = (address_: string) => {
    address = address_;

    observers.forEach((observer) => {
      observer(address_);
    });
  };

  const saveWalletState_ = () => {
    const state = { address };

    sessionStorageHelper.setWithExpire(
      WALLET_STATE_KEY,
      JSON.stringify(state),
      MAX_WALLET_CONNECTION_TIME,
    );
  };

  const restoreWalletState_ = () => {
    const { value, hasExpired } =
      sessionStorageHelper.getWithExpire(WALLET_STATE_KEY);

    if (hasExpired) {
      return false;
    }

    if (!value) {
      return false;
    }

    try {
      const { address } = JSON.parse(value);

      if (!address) {
        return false;
      }

      // 현재는 메타마스크만 지원하기에 있는 임시 코드. 추후에 일반적인 방식으로 변경 필요
      connector = MetamaskConnector;
      connector.restoreState(address);
      setCurrentAddress_(address);
    } catch (e) {
      return false;
    }

    return true;
  };

  // public methods
  const config = (options: IWalletManagerOptions) => {
    walletOptions = options;

    if (walletOptions.walletAutoConnect) {
      restoreWalletState_();
    }
  };

  const setCurrentAddress = setCurrentAddress_;

  const getCurrentAddress = () => address;

  const setWalletConnector = (connector_: any) => {
    connector = connector_;

    if (connector.getCurrentAddress()) {
      setCurrentAddress(connector.getCurrentAddress());
    }

    connector.setAddressChangeCallback((address_: string) => {
      setCurrentAddress(address_);
    });

    saveWalletState_();
  };

  const getWalletConnector = () => connector;

  const addObserver = (callback: Function) => {
    observers.push(callback);
  };

  // connector interface
  const getBalance = async () => {
    if (!connector) {
      return null;
    }

    return connector.getBalance();
  };

  const disconnect = () => {
    if (!connector) {
      return;
    }

    sessionStorageHelper.removeItem(WALLET_STATE_KEY);
    setCurrentAddress_('');
    connector = null;
  };

  const that = {
    address,
    connector,
    config,
    getCurrentAddress,
    setWalletConnector,
    getWalletConnector,
    addObserver,
    getBalance,
    disconnect,
  };

  return that;
})();

// singleton object with closure pattern
const WalletManager = (() => {
  let instance: typeof WalletManagerInst;
  let walletOptions: IWalletManagerOptions;

  const init = () => {
    const manager = WalletManagerInst;
    manager.config(walletOptions);

    return manager;
  };

  const getInstance = () => {
    if (!instance) {
      instance = init();
    }
    return instance;
  };

  const config = (options: IWalletManagerOptions) => {
    walletOptions = options;

    init();
  };

  const that = {
    getInstance,
    config,
  };

  return that;
})();

export default WalletManager;
