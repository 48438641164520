import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as AttentionIcon } from '../../assets/images/attention_icon.svg';
import { ReactComponent as CurrencyIcon } from '../../assets/images/currency_icon.svg';
import { ReactComponent as OpportunityIcon } from '../../assets/images/opportunity_icon.svg';
import { ReactComponent as ProjectIcon } from '../../assets/images/project_icon.svg';
import { ReactComponent as TimeIcon } from '../../assets/images/time_icon.svg';
import { urls } from '../../assets/urls';
import { MetadataContext } from '../../contexts/MetadataContext';
import { MenuItemLayout } from './MenuItemLayout';
import { motion } from 'framer-motion';
import styles from './styles.module.scss';
import TitleChip from '../TitleChip/TitleChip';
import TitleHeader from '../TitleChip/TitleHeader';

export const ProjectCardFamily = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const suit = searchParams.get('suit');
  const { metadata } = useContext(MetadataContext);
  const [mySuit, setMySuit] = useState<string>('');
  const [myNumber, setMyNumber] = useState<string>('');

  const getSuitCard = () => {
    let Icon;
    let iconSize;

    switch (suit) {
      case 'Time':
        Icon = TimeIcon;
        iconSize = (41 / 94) * 100;
        break;
      case 'Attention':
        Icon = AttentionIcon;
        iconSize = (58 / 94) * 100;
        break;
      case 'Currency':
        Icon = CurrencyIcon;
        iconSize = (59 / 94) * 100;
        break;
      case 'Opportunity':
        Icon = OpportunityIcon;
        iconSize = (67 / 94) * 100;
        break;
      default:
        Icon = TimeIcon;
        iconSize = (41 / 94) * 100;
        break;
    }
    return (
      <div className={styles.front_card}>
        <Icon style={{ width: `${iconSize}%` }} />
        {suit}
      </div>
    );
  };

  const getMyCard = (index: number) => {
    const myCardBackVariants = {
      hidden: {
        rotateY: '180deg',
        display: 'block',
      },
      visible: {
        rotateY: '90deg',
        transition: {
          duration: 0.5,
          ease: 'easeIn',
        },
        transitionEnd: {
          display: 'none',
        },
      },
    };

    const myCardFrontVariants = {
      hidden: { rotateY: '90deg', display: 'none' },
      visible: {
        display: 'block',
        rotateY: '0deg',
        transition: {
          ease: 'easeOut',
          delay: 0.5,
          duration: 0.5,
        },
      },
    };

    const handleMyCardClick = (event: any) => {
      event.stopPropagation();
      navigate('mynft');
    };

    if (mySuit === suit && myNumber === index.toString()) {
      return (
        <div
          className={styles.mycard_wrapper}
          onClick={(event) => handleMyCardClick(event)}
        >
          <motion.img
            initial="hidden"
            animate="visible"
            variants={myCardBackVariants}
            src={`${urls.baseUrl}/Images/back_gold.png`}
            alt="card back"
          />
          <motion.img
            className={styles.mycard_front}
            initial="hidden"
            animate="visible"
            variants={myCardFrontVariants}
            src={`${
              urls.baseUrl
            }/Images/${mySuit.toLocaleLowerCase()}/${index}.png`}
            alt="card front"
          />
          <div />
        </div>
      );
    } else {
      return <img src={`${urls.baseUrl}/Images/back.png`} alt="card back" />;
    }
  };

  const getCardButton = (card: string) => {
    let Icon;
    let iconSize;

    switch (card) {
      case 'Time':
        Icon = TimeIcon;
        iconSize = (41 / 94) * 100;
        break;
      case 'Attention':
        Icon = AttentionIcon;
        iconSize = (58 / 94) * 100;
        break;
      case 'Currency':
        Icon = CurrencyIcon;
        iconSize = (59 / 94) * 100;
        break;
      case 'Opportunity':
        Icon = OpportunityIcon;
        iconSize = (67 / 94) * 100;
        break;
      default:
        Icon = TimeIcon;
        iconSize = (41 / 94) * 100;
        break;
    }

    const handleClick = () => {
      navigate(`/project/cards/family?suit=${card}`);
    };

    return (
      <div className={styles.card_button} onClick={handleClick}>
        <Icon style={{ width: `${iconSize}%` }} />
      </div>
    );
  };

  const getAnotherCard = () => {
    let suitList;

    switch (suit) {
      case 'Time':
        suitList = ['Attention', 'Currency', 'Opportunity'];
        break;
      case 'Attention':
        suitList = ['Time', 'Currency', 'Opportunity'];
        break;
      case 'Currency':
        suitList = ['Time', 'Attention', 'Opportunity'];
        break;
      case 'Opportunity':
        suitList = ['Time', 'Attention', 'Currency'];
        break;
      default:
        suitList = ['Time', 'Attention', 'Currency', 'Opportunity'];
        break;
    }

    return (
      <div className={styles.another_card}>
        {suitList.map((item, index) => (
          <div className={styles.card_button_wrapper} key={index}>
            {getCardButton(item)}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (!metadata.attributes) return;

    metadata.attributes.forEach((attribute: any) => {
      if (attribute.trait_type === 'type') {
        setMySuit(attribute.value);
      }
      if (attribute.trait_type === 'number') {
        setMyNumber(attribute.value);
      }
    });
  }, [metadata]);

  return (
    <div className={styles.project}>
      <TitleHeader icon={<ProjectIcon />} text={'The Project'} />
      <div className={styles.card_family_title}>
        <span />
        {getSuitCard()}
        <img src={`${urls.baseUrl}/Images/back.png`} alt="card back" />
      </div>
      <div className={styles.card_family_deck}>
        {Array.from({ length: 12 }).map((_, index) => (
          <div key={index}>{getMyCard(index)}</div>
        ))}
      </div>
      {getAnotherCard()}
    </div>
  );
};
