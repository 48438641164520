import Web3 from 'web3';
import { Web3ContractEncoder } from '../../contract';
import {
  InvalidTransactionReceiptException,
  TransactionException,
  TransactionExceptionType,
} from '../../exception/transaction';
import { Ethereum, EthereumInjectedWindow } from '../../interface/ethereum';
import {
  ITransactionData,
  ITransactionResult,
} from '../../interface/transaction';
import { getDcentLink } from './common';

interface DcentEthereum extends Ethereum {
  isDcentWallet: boolean;
}

interface DcentEthereumInjectedWindow extends Window {
  ethereum?: DcentEthereum;
}

export const DcentConnector = (() => {
  let onAddressChangeCallback: any = null;
  const state = { address: '' };
  let web3Inst: Web3;
  const { ethereum } = window as EthereumInjectedWindow;
  const contractEncoder = Web3ContractEncoder;
  contractEncoder.config(ethereum);

  const setAddressChangeCallback = (callback: (address: string) => void) => {
    onAddressChangeCallback = callback;
  };

  const getCurrentAddress = () => state.address;

  const setCurrentAddress = (address: string) => {
    state.address = address;
    if (onAddressChangeCallback) {
      onAddressChangeCallback(address);
    }
  };

  const hasExtension = () => {
    const { ethereum } = window as DcentEthereumInjectedWindow;
    if (!ethereum) {
      return false;
    }

    return ethereum.isDcentWallet ?? false;
  };

  const activate = async () => {
    const { ethereum } = window as DcentEthereumInjectedWindow;
    if (!ethereum) {
      return false;
    }

    try {
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });

      if (accounts.length === 0) return false;

      const [address] = accounts;
      setCurrentAddress(address);

      web3Inst = new Web3(ethereum);
    } catch (e) {
      return false;
    }

    return true;
  };

  const restoreState = (address: string) => {
    const { ethereum } = window as DcentEthereumInjectedWindow;
    if (!ethereum) {
      return false;
    }

    web3Inst = new Web3(ethereum);

    setCurrentAddress(address);

    return true;
  };

  const getInstallLink = () => {
    return getDcentLink(window.location.href, 'ethereum-mainnet');
  };

  const sign = async (message: string) => {
    const { ethereum } = window as DcentEthereumInjectedWindow;
    if (!ethereum) {
      throw new Error(
        'Failed to sign message: Ethereum provider is not available',
      );
    }

    try {
      const signature = await ethereum.request({
        method: 'personal_sign',
        params: [message, state.address],
      });

      return signature;
    } catch (e) {
      return '';
    }
  };

  const sendTransaction = async (tx: ITransactionData) => {
    const { abi, params } = tx;
    const contract = contractEncoder.encodeContract(abi, tx.to);

    try {
      const gas = await contract.methods[tx.functionName](
        ...params,
      ).estimateGas({
        from: state.address,
        value: tx.value,
      });
      const receipt = await web3Inst?.eth.sendTransaction({
        from: state.address,
        to: tx.to,
        value: tx.value,
        gas: tx.gas || gas,
        gasPrice: tx.gasPrice,
        // NOTE: https://stackoverflow.com/questions/68926306/avoid-this-gas-fee-has-been-suggested-by-message-in-metamask-using-web3
        maxFeePerGas: tx.maxFeePerGas,
        maxPriorityFeePerGas: tx.maxPriorityFeePerGas,
        data: contract.methods[tx.functionName](...params).encodeABI(),
        nonce: tx.nonce,
        chainId: tx.chainId,
        hardfork: tx.hardfork,
        common: tx.common,
      });

      if (!receipt) throw new InvalidTransactionReceiptException();

      const result: ITransactionResult = {
        transactionHash: receipt.transactionHash,
        receipt: {
          status: receipt.status ? '1' : '0',
          transactionHash: receipt.transactionHash,
          transactionIndex: receipt.transactionIndex,
          blockHash: receipt.blockHash,
          blockNumber: receipt.blockNumber,
          gasUsed: receipt.gasUsed,
          from: receipt.from,
          to: receipt.to,
        },
        nativeReceipt: receipt,
      };

      return result;
    } catch (e) {
      if (e instanceof InvalidTransactionReceiptException) {
        throw e;
      }

      // TODO: Add additional error handling for different types of wallet errors
      throw new TransactionException(
        TransactionExceptionType.GENERAL,
        e as string,
      );
    }
  };

  const getBalance = async () => {
    if (!web3Inst) return null;

    try {
      const rawBalance = await web3Inst.eth.getBalance(state.address);
      const balance = web3Inst.utils.fromWei(rawBalance, 'ether');

      return balance;
    } catch (e) {
      return null;
    }
  };

  const that = {
    ...state,
    setAddressChangeCallback,
    getCurrentAddress,
    hasExtension,
    activate,
    restoreState,
    getInstallLink,
    sign,
    sendTransaction,
    getBalance,
  };

  return that;
})();
