import { useNavigate } from 'react-router-dom';
import { ReactComponent as AttentionIcon } from '../../assets/images/attention_icon.svg';
import { ReactComponent as CurrencyIcon } from '../../assets/images/currency_icon.svg';
// import { ReactComponent as NextIcon } from '../../assets/images/next_icon.svg';
import { ReactComponent as OpportunityIcon } from '../../assets/images/opportunity_icon.svg';
import { ReactComponent as ProjectIcon } from '../../assets/images/project_icon.svg';
import { ReactComponent as TimeIcon } from '../../assets/images/time_icon.svg';
import { MenuItemLayout } from './MenuItemLayout';
import styles from './styles.module.scss';
import TitleChip from '../TitleChip/TitleChip';
import LanguageToggle from '../LanguageToggle/LanguageToggle';
import { useTranslation } from 'react-i18next';
import NextIcon from '@mui/icons-material/ArrowForward';
import TitleHeader from '../TitleChip/TitleHeader';

export const ProjectIntro = () => {
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();

  const changeLanguage = () => {
    if (i18n.language === 'ko') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('ko');
    }
  };

  // const handleNextClick = () => {
  //   navigate('/project/cards');
  // };

  return (
    <div className={styles.project}>
      <TitleHeader icon={<ProjectIcon />} text={'The Project'} isBlack />
      <div className={styles.project_content_1}>
        <div className={styles.container}>
          <div className={styles.line_container}>
            <div className={styles.icon_container}>
              <OpportunityIcon />
            </div>
            <p>For</p>
          </div>
          <div className={styles.line_container}>
            <div className={styles.icon_container}>
              <TimeIcon />
            </div>
            <p>what</p>
          </div>
          <div className={styles.line_container}>
            <div className={styles.icon_container}>
              <CurrencyIcon />
            </div>
            <p>it's</p>
          </div>
          <div className={styles.line_container}>
            <div className={styles.icon_container}>
              <AttentionIcon />
            </div>
            <p>worth</p>
          </div>
        </div>
      </div>
      <div
        className={
          i18n.language === 'ko'
            ? styles.project_content_2Kr
            : styles.project_content_2
        }
      >
        {t('PROJECT_DESCRIPTION_1')} <br />
        <br />
        {t('PROJECT_DESCRIPTION_2')} <br />
        <br />
        {t('PROJECT_DESCRIPTION_3')}
        <br />
        <br />
        <strong>- Ryan Gander OBE RA</strong>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      {/* <LanguageToggle
          isKorean={i18n.language === 'ko'}
          onClick={() => {
            changeLanguage();
          }}
        />
        <div className={styles.footer}>
          <div className={styles.icon_container}>
            <span />
            <NextIcon fontSize="large" onClick={handleNextClick} />
          </div>
        </div> */}
      <LanguageToggle
        isKorean={i18n.language === 'ko'}
        onClick={() => {
          changeLanguage();
        }}
      />
      <div
        className={styles.nextIconWrapper}
        onClick={(event) => {
          event.stopPropagation();
          navigate('/project/cards');
        }}
      >
        <NextIcon fontSize="large" />
      </div>
    </div>
  );
};
