import { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as MynftIcon } from '../../assets/images/mynft_icon.svg';
import { urls } from '../../assets/urls';
import { MetadataContext } from '../../contexts/MetadataContext';
import { MenuItemLayout } from './MenuItemLayout';
import { motion, useAnimate } from 'framer-motion';
import ReactPlayer from 'react-player';
import styles from './styles.module.scss';
import { isIOS } from 'react-device-detect';
import TitleHeader from '../TitleChip/TitleHeader';

const duration = 2;

export const MyNFT = () => {
  const [scope, animate] = useAnimate();
  const { metadata } = useContext(MetadataContext);
  const [animationUrl, setAnimationUrl] = useState<string | null>();
  const [sentence, setSentence] = useState<string>('');
  const [shownSentence, setShownSentence] = useState<string>('');
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const animationTimer = useRef<any>(null);
  const animationStateRef = useRef<string>('white');
  const numberListRef = useRef<boolean[]>([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [animationState, setAnimationState] = useState<string>('white');
  const [numberList, setNumberList] = useState<boolean[]>([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const titleVariants = {
    white: {
      color: '#fff',
      opacity: 1,
      transition: {
        duration,
        ease: 'easeInOut',
      },
    },
    yellow: {
      color: '#CEB350',
      opacity: 1,
      transition: {
        duration,
        ease: 'easeInOut',
      },
    },
  };
  const fadeoutVariants = {
    hidden: {
      opacity: 0,
      transition: {
        duration,
        ease: 'easeInOut',
      },
      transitionEnd: {
        display: 'none',
      },
    },
    visible: {
      opacity: 1,
      display: 'flex',
      transition: {
        duration,
        ease: 'easeInOut',
      },
    },
  };
  const fadeinVariants = {
    hidden: {
      opacity: 0,
      display: 'none',
      transition: {
        duration,
        ease: 'easeInOut',
      },
    },
    visible: {
      opacity: 1,
      display: 'block',
      zIndex: 2000,
      transition: { delay: duration, duration, ease: 'easeInOut' },
      transitionEnd: {
        zIndex: 0,
      },
    },
  };
  const backgroundAnimation = async () => {
    await animate(
      scope.current,
      { opacity: 1, display: 'block', zIndex: 2000 },
      { duration: 2, ease: 'easeInOut' },
    );
    await animate(
      scope.current,
      { zIndex: 0 },
      { duration: 0, delay: 4 + sentence.length * 0.08, ease: 'easeInOut' },
    );
  };

  const getCardNumber = (number: string, index: number) => {
    const numberVariants = {
      hidden: {
        color: '#fff',
        opacity: 0.15,
        transition: {
          duration,
          ease: 'easeInOut',
        },
      },
      white: {
        color: '#fff',
        opacity: 1,
        transition: {
          duration,
          ease: 'easeInOut',
        },
      },
      yellow: {
        color: '#CEB350',
        opacity: 1,
        transition: {
          duration,
          ease: 'easeInOut',
        },
      },
    };

    return (
      <motion.span
        initial="white"
        animate={numberList[index] ? animationState : 'hidden'}
        variants={numberVariants}
      >
        {number}
      </motion.span>
    );
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
    setTimeout(() => {
      const video = document.querySelector('video');
      if (video) {
        video.play();
      }
    }, 2000);
  };

  useEffect(() => {
    if (metadata) {
      setAnimationUrl(metadata.animation_url);
      setSentence(metadata.sentence);
    }
  }, [metadata]);

  useEffect(() => {
    if (!isClicked) {
      animationTimer.current = setInterval(() => {
        if (animationStateRef.current === 'white') {
          animationStateRef.current = 'yellow';
          setAnimationState('yellow');
        } else {
          animationStateRef.current = 'white';
          setAnimationState('white');
        }

        const newNumberList = numberListRef.current.map(
          () => Math.random() < 0.5,
        );
        numberListRef.current = newNumberList;
        setNumberList(newNumberList);
      }, duration * 1000);

      return () => clearInterval(animationTimer.current);
    } else {
      backgroundAnimation();

      sentence.split('').forEach((char, index) => {
        setTimeout(() => {
          setShownSentence((prev) => prev + char);
        }, index * 80 + 4000);
      });
    }
  }, [isClicked]);

  return (
    <div className={styles.my_nft}>
      <TitleHeader icon={<MynftIcon />} text={'My NFT'} />
      <motion.div
        initial="visible"
        animate={isClicked ? 'hidden' : 'visible'}
        variants={fadeoutVariants}
        className={styles.content1}
      >
        <div className={styles.image_wrapper} onClick={handleClick}>
          <div>
            <img src={`${urls.baseUrl}/Images/back_gold.png`} alt="back" />
            <span className={styles.card_shadow} />
            <span className={styles.card_halo} />
          </div>
          <motion.div
            initial="white"
            animate={animationState}
            variants={titleVariants}
            className={styles.title}
          >
            What do you value?
          </motion.div>
        </div>
        <div className={styles.text_wrapper}>
          <div className={styles.deck}>
            <div className={styles.line}>
              {getCardNumber('2', 0)}
              {getCardNumber('3', 1)}
              {getCardNumber('4', 2)}
              {getCardNumber('5', 3)}
              {getCardNumber('6', 4)}
            </div>
            <div className={styles.line}>
              {getCardNumber('7', 5)}
              {getCardNumber('8', 6)}
              {getCardNumber('9', 7)}
              {getCardNumber('10', 8)}
            </div>
            <div className={styles.line}>
              {getCardNumber('J', 9)}
              {getCardNumber('Q', 10)}
              {getCardNumber('K', 11)}
              {getCardNumber('A', 12)}
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div
        ref={scope}
        initial="hidden"
        variants={fadeinVariants}
        className={styles.content2}
      >
        <motion.div
          initial="hidden"
          animate={isClicked ? 'visible' : 'hidden'}
          variants={fadeinVariants}
          className={styles.image_wrapper}
        >
          {animationUrl &&
            // <video autoPlay loop muted playsInline>
            //   <source src={animationUrl} type="video/mp4" />
            // </video>
            (isIOS ? (
              <img src={animationUrl.slice(0, -4) + '.gif'} alt="testimage" />
            ) : (
              <ReactPlayer
                url={animationUrl}
                playing
                loop
                muted
                playsinline
                width="100%"
                height="100%"
                controls={isIOS}
              />
            ))}
        </motion.div>
        <motion.div
          initial="hidden"
          animate={isClicked ? 'visible' : 'hidden'}
          variants={fadeinVariants}
          className={styles.text_wrapper}
        >
          {shownSentence}
        </motion.div>
      </motion.div>
    </div>
  );
};
