export default {
  // connect wallet
  ARTIST_DESCRIPTION_1:
    'Ryan Gander has established an international reputation through artworks that materialise in many different forms, ranging from sculpture, apparel and writing to architecture, painting, typefaces, publications and performance.',
  ARTIST_DESCRIPTION_2:
    'As well as curating exhibitions, he is a committed educator, having taught at international art institutions and universities, and has written and presented television programmes on and about contemporary art and culture for the BBC.',
  ARTIST_DESCRIPTION_3:
    'Through associative thought processes that connect the everyday and the esoteric, the overlooked and the commonplace, Gander’s work involves a questioning of language and knowledge, as well as a reinvention of both the modes of appearance and the creation of an artwork.',
  ARTIST_DESCRIPTION_4:
    'His work can be reminiscent of a puzzle, or a network with multiple connections and the fragments of an embedded story. It is ultimately a huge set of hidden clues to be deciphered, encouraging viewers to make their own associations and invent their own narrative in order to unravel the complexities staged by the artist.',
  ARTIST_DESCRIPTION_5:
    'Ryan Gander is an artist living and working in Suffolk, UK. He studied at Manchester Metropolitan University, UK, the Rijksakademie van Beeldende Kunsten, Amsterdam, NL and the Jan van Eyck Akademie, Maastricht, NL.',
  ARTIST_DESCRIPTION_6:
    'The artist has been a Professor of Visual Art at the Universities of Huddersfield and Suffolk and holds an honorary Doctor of the Arts at the Manchester Metropolitan University and the University of Suffolk.',
  ARTIST_DESCRIPTION_7:
    'In 2017 he was awarded an OBE for services to contemporary arts. In 2019 he was awarded the Hodder Fellowship at Princeton University. In 2022, he was made RA for the category of Sculpture.',
  PROJECT_DESCRIPTION_1:
    '“When we think of value, we think of money, but this is only one value system, there are, of course, many others. Some systems have greater importance than others depending on who you are, your culture and your history.',
  PROJECT_DESCRIPTION_2:
    'An airline ticket represents the value of privilege; a library of knowledge; a magpie of superstition; a key of access; a watch of time; health of longevity; a building of safety; a dice of chance.',
  PROJECT_DESCRIPTION_3:
    'For many, opportunity, time, attention, and agency are our greatest values, not money. If you ask a billionaire who is terminally ill to give up their money to live a long life, of course they will.”',
};
