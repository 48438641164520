import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { IABIItem } from '../interface/abi';
import { IContract } from '../interface/contract';

const Web3ContractEncoder = (() => {
  let web3Inst: Web3;

  const config = (web3Provider: any) => {
    web3Inst = new Web3(web3Provider);
  };

  const encodeContract = (abi: IABIItem[], contractAddress?: string) => {
    if (web3Inst) {
      const contract = new web3Inst.eth.Contract(
        abi as unknown as AbiItem,
        contractAddress,
      );

      return contract as IContract;
    }

    return {} as IContract;
  };

  const that = { config, encodeContract };

  return that;
})();

export default Web3ContractEncoder;
