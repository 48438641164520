export default {
  // connect wallet
  ARTIST_DESCRIPTION_1:
    '라이언 갠더는 조각, 의류, 글쓰기, 건축, 회화, 서체, 출판물 및 퍼포먼스에 이르기까지 다양한 형태로 구현되는 작품들을 통해 국제적 명성을 확립해 왔다. 그는 전시를 기획하는 것뿐만 아니라 국제적 예술 기관과 대학에서 가르치는 헌신적인 교육자이며, BBC에서 현대 예술과 문화에 관한 텔레비전 프로그램을 집필하고 발표하기도 했다.',
  ARTIST_DESCRIPTION_2:
    '일상적이고 신비로운, 간과된 것과 익숙한 것을 연결하는 연상적인 사고 과정을 통해 갠더의 작품은 언어와 지식에 대해 질문하고 예술 작품 창작과 형태의 구현 방식을 새롭게 창조한다.',
  ARTIST_DESCRIPTION_3:
    '그의 작품은 퍼즐이나 다양한 연결과 내재된 이야기의 단편들로 이루어진 네트워크를 연상시킨다.그것은 궁극적으로 해독해야 할 숨겨진 단서들의 거대한 집합이며, 예술가가 제시한 복잡성을 풀기 위해 관객들이 자신만의 연관성을 만들고 이야기를 창조하도록 격려한다.',
  ARTIST_DESCRIPTION_4:
    '라이언 갠더는 영국 서퍽에 거주하며 활동 중이다. 그는 영국 맨체스터 메트로폴리탄 대학교, 네덜란드 암스테르담 라익스아카데미, 네덜란드 마스트리흐트 얀 반 에이크 아카데미에서 수학했다. 그는 영국 허더필드 대학와 서퍽 대학에서 순수 예술 교수로 재직했으며, 맨체스터 메트로폴리탄 대학에서 명예 박사 학위를 취득했다. ',
  ARTIST_DESCRIPTION_5:
    '2017년에는 동시대 미술에 기여한 공로로 대영국훈장(OBE)을 받았으며, 2019년에는 프린스턴 대학교에서 호더 펠로우십을 수상했다. 2022년에는 조각 분야로 로얄 아카데미원(RA) 회원이 되었다.',
  ARTIST_DESCRIPTION_6: '',
  ARTIST_DESCRIPTION_7: '',
  PROJECT_DESCRIPTION_1:
    '“우리가 가치를 생각할 때 돈을 떠올리지만, 이것은 오직 하나의 가치 체계일 뿐이며, 물론 다른 많은 체계가 존재한다. 어떤 체계는 당신이 누구이며, 당신의 문화와 역사에 따라 중요도가 달라진다.',
  PROJECT_DESCRIPTION_2:
    '항공권은 특권의 가치를 대변한다; 지식의 도서관; 미신의 까마귀; 접근의 열쇠; 시간의 시계; 장수의 건강; 안전의 건물; 운의 주사위. 많은 사람에게 기회, 시간, 주목과 주체성은 가장 큰 가치다, 돈이 아니다.',
  PROJECT_DESCRIPTION_3:
    '만약 임종을 앞둔 억만장자에게 장수하기 위해 돈을 포기하라 한다면, 물론 그들은 그렇게 할 것이다.”',
};
